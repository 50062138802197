<template>
  <Details
    title="Application"
    resource="apps"
    base-path="applications.index"
  >
    <template v-slot:side-panel>
      <DetailsSidePanel entity-title="application" new-entity></DetailsSidePanel>
    </template>
    <template v-slot:default="detailsProps">
      <ApplicationForm
        :on-submit="detailsProps.handleSubmit"
        :loading="detailsProps.loading"
        :initial-values="initialValues"
        @close="detailsProps.redirectToList"
      />
    </template>
  </Details>
</template>

<script>
  import ModalNavigation from '@/mixins/ModalNavigation';
  import Details from '@/components/auth/details/Details';
  import DetailsSidePanel from "@/components/auth/details/DetailsSidePanel";
  import ApplicationForm from '@/views/auth/applications/ApplicationForm';

  export default {
    name: 'ApplicationsCreate',
    data: function() {
      return {
        initialValues: {
          isCommunityAgnostic: false,
          isCustomerUserAgnostic: false,
        }
      }
    },
    components: {
      Details,
      DetailsSidePanel,
      ApplicationForm,
    },
    mixins: [ModalNavigation],
  };
</script>
